const Constants = {
    RC_SUCCESS : "0",
    RC_ERR_USER_DISABLED : "-1",
    RC_ERR_USER_LOCKED : "-2",
    RC_ERR_PWD_EXPIRED : "-3",
    RC_ERR_SESSION_EXPIRED : "-4",
    RC_ERR_NOT_IN_WHITE_LIST : "-6",
    RC_ERR_NOTAUTHORIZED : "-10",
    RC_ERR_READONLY : "-11",
    RC_ERR_NOTFOUND : "-20",
    RC_ERR_LANGUAGE_UNKNOWN : "-30",
    RC_ERR_CACHE_FAILED : "-40",
    RC_ERR_MAINTENANCE_MODE : "-40",

    /*******************************************************************
     * Start kit groups
     *******************************************************************/
    GROUP_GROUPS : "groups",
    GROUP_APP : "app",
    GROUP_SECURITY : "securityZone",
    GROUP_PROFILE : "profile",
    GROUP_LANGUAGE : "language",
    GROUP_DAYS : "day",
    GROUP_MONTHS : "month",
    GROUP_USER_STATUS : "userStatus",
    GROUP_FOLDER : "folder",
    GROUP_COMMENT : "groupKeyComment",
    GROUP_DOMAINLANGUAGE : "domainLanguage",
    GROUP_WHITE_LIST_PROFILES : "WhiteListProfile",

    /*******************************************************************
     * Forbidden for everyone security zone
     *******************************************************************/
    SECZONE_LOCAL_ONLY : 'local_only',
    REDIRECT_AFTER_LOGIN : 'redirectAfterLogin',
    REDIRECT_AFTER_LOGIN_DEFAULT : '-',

    /*******************************************************************
     * Access levels
     *******************************************************************/
    SECURITY_NOACCESS : 0,
    SECURITY_READONLY : 1,
    SECURITY_FULLACCESS : 2,

    /*******************************************************************
     * Reloads cache type
     *******************************************************************/
    RELOAD_NONE : "none",
    RELOAD_ALL : "all",
    RELOAD_SPECIFIC : "specific",

    RELOAD_FLAG : 'mustReload',

    /*******************************************************************
     * App Parameters value
     *******************************************************************/
    APP_DEFAULT_LANGUAGE : 'defaultLanguage',
    APP_ARCHIVE_STORY : 'archiveStory',
    APP_ARCHIVE_ENTITY : 'archiveEntity',
    APP_PHONE_IS_MANDATORY : 'phoneIsMandatory',
    APP_LANGUAGES : 'languages',
    APP_MAJOR : 'versionMajor',
    APP_MINOR : 'versionMinor',
    APP_REVISION : 'versionRevision',

    APP_NEED_LOGIN : 'needLogin',
    APP_ROUTE_LOGIN : 'routeLogin',
    APP_ROUTE_HOME : 'routeHome',
    APP_MAINTENANCE_MODE : 'maintenanceMode',
    APP_DATE_FORMAT : 'dateFormat',
    APP_SESSION_LENGTH : 'sessionLength',
    APP_INFO_TIMEOUT : 'infoTimeout',
    APP_ERROR_TIMEOUT : 'errorTimeout',

    APP_API_URL : 'apiUrl',
    APP_API_SERVICE_ACCOUNT : 'apiServiceAccount',
    APP_API_SERVICE_PASSWORD : 'apiServicePwd',
    APP_PROJECT_CODE : 'project',

    APP_CONVERT_RATIO : 'imageConvertRatio',
    APP_ASSETS_ROOT : 'assetsRoot',
    APP_USE_S3 : 'useS3',
    APP_AWS_KEY : 'AWSKey',
    APP_AWS_SECRET : 'AWSSecret',
    APP_AWS_BUCKET : 'AWSBucket',
    APP_AWS_ZONE : 'AWSZone',

    /*******************************************************************
     * Parameters value
     *******************************************************************/
    PARAM_YES : "yes",
    PARAM_NO : "no",
}

export default Constants
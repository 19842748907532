import {BaseApiService} from "../BaseApiService";
import ApiResult from "../../Model/ApiResult";

/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLogin
 *******************************************************************/
export default class ApiUsers extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/
    async me(data) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + `/me`);
        this.setBody(data);

        return await this.callApi();
    }

    /********************/
    async getUsers(search = "", state = "", profile = "", language = "", page = 1) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] +`/users?search=${search}&state=${state}&profile=${profile}&language=${language}&page=${page}`);

        return await this.callApi();
    }

    /********************/
    async updateUser(id, user) {
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(user);
        this.setApiRoute(sessionStorage['language'] +`/users/${id}`);

        return await this.callApi();
    }

    /********************/
    async addUser(user) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(user);
        this.setApiRoute(sessionStorage['language'] +`/users`);

        return await this.callApi();
    }

    /********************/
    async disable( id) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] +`/users/${id}/disable`);

        return await this.callApi();
    }

    /********************/
    async enable( id) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] +`/users/${id}/enable`);

        return await this.callApi();
    }

    /********************/
    async lock( id) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] +`/users/${id}/lock`);

        return await this.callApi();
    }

    /********************/
    async unlock(id) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + `/users/${id}/unlock`);

        return await this.callApi();
    }

    /********************/
    async get(id) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] +`/users/${id}`);

        return await this.callApi();
    }
}

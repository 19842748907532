import {BaseApiService} from "../BaseApiService";

export default class ApiProfiles extends BaseApiService {

    // Get rights
    async getRights() {
        this.setGetMethod();
        this.setSecureByUser(); //todo check secureByService
        this.setApiRoute(sessionStorage['language'] + "/profiles");

        return await this.callApi();
    }

    // Add profile
    async addProfile(profile) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(profile);
        this.setApiRoute(sessionStorage['language'] + "/profiles");

        return await this.callApi();
    }

    // Update profile
    async updateProfile(profile, zones) {
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(zones);
        this.setApiRoute(sessionStorage['language'] + "/profiles/" + profile);

        return await this.callApi();
    }

    // Delete profile
    async deleteProfile(profile) {
        this.setDeleteMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/profiles/" + profile);

        return await this.callApi();
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    position:fixed;
    top:0px;
    right:15px;
    z-index:40;
    border: solid 1px #aaaaaa;
    border-radius: 5px;
    font-weight: bold;  
    box-shadow: 2px 2px 0px #999999;
}

.message.error {
    background-color: red;
    color: yellow;
}

.message.info {
    background-color: #00558a;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/StarterKit/css/message.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,OAAO;IACP,UAAU;IACV,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".message {\n    position:fixed;\n    top:0px;\n    right:15px;\n    z-index:40;\n    border: solid 1px #aaaaaa;\n    border-radius: 5px;\n    font-weight: bold;  \n    box-shadow: 2px 2px 0px #999999;\n}\n\n.message.error {\n    background-color: red;\n    color: yellow;\n}\n\n.message.info {\n    background-color: #00558a;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
